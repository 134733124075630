import { createCardToken, SecurityCode } from '@mercadopago/sdk-react'
import BackButton from '../../commons/BackButton'
import { LockOutlined } from '@mui/icons-material'
import PoweredBy from './PoweredBy'

export default ({ card, onBack, onSubmit }) => {
  return (
    <>
      <div className='px-6 w-full flex flex-col justify-between flex-grow'>
        <div>
          <div className='flex w-full mb-3 md:hidden'>
            <BackButton handleBack={onBack} paddingLeft={0} />
          </div>
          <p className='text-lg mb-8 text-center'>Completá los datos de tu tarjeta</p>
          <Form card={card} onBack={onBack} />
        </div>
        <div>
          <Button card={card} onSubmit={onSubmit} />
          <PoweredBy />
        </div>
      </div>
    </>
  )
}

const Form = ({ card, onBack }) => {
  const { issuer, payment_method, last_four_digits } = card

  const cardType = payment_method.payment_type_id === 'credit_card' ? 'crédito' : 'débito'

  const image = () => {
    try {
      return require(`../../assets/img/${issuer.name.toLowerCase()}.webp`)
    } catch (error) {
      return require('../../assets/img/default_card.webp')
    }
  }

  return (
    <div className='rounded-lg flex flex-col items-center w-full py-5 bg-white'>
      <div className='flex flex-row px-5 w-full items-center'>
        <div className='flex items-center justify-center rounded-full p-2 border-2 border-light_gray'>
          <img src={image()} className='w-5 h-5' />
        </div>
        <div className='ml-4 text-start font-thin'>
          <p className='flex text-sm items-start gap-1'>
            {issuer.name} <span className='text-xs align'>****</span> {last_four_digits}
          </p>
          <p className='text-gray text-xs'>
            {issuer.name} {cardType}
          </p>
        </div>
        <p className='hidden md:block text-light_coral ml-auto cursor-pointer' onClick={onBack}>
          Volver
        </p>
      </div>
      <div className='w-full h-[2px] bg-light_gray mt-4 mb-8' />
      <div className='flex flex-row px-5 w-full items-center'>
        <div className='flex items-center justify-center rounded-full p-2 border-2 border-light_gray min-w-[40px]'>
          <img src={require(`../../assets/img/cvv.webp`)} className='w-5 h-5' />
        </div>
        <div className='ml-4 text-start'>
          <p className='font-thin text-xs mb-1'>Código de seguridad</p>
          <SecurityCode
            style={{
              height: '40px'
            }}
            length={card.security_code_length}
            placeholder='123'
          />
        </div>
      </div>
    </div>
  )
}

const Button = ({ card, onSubmit }) => {
  const handleSubmit = async () => {
    try {
      const token = await createCardToken({ cardId: card.id })

      const cardData = {
        token: token.id
      }

      onSubmit(cardData)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <button
      onClick={handleSubmit}
      className='text-white flex items-center justify-center w-full bg-primary gap-4 text-base rounded-[7px] py-4 mb-4'
    >
      <LockOutlined fontSize='xsmall' color='white' /> Pagar
    </button>
  )
}
